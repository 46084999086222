import { UrlResolver } from '@angular/compiler';
import { Component } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  //orderid
  constructor(private router: Router) { }
  title = 'Demo';
  en="/en/Orderdetails";
  ar="/ar/Orderdetails";
  ngOnInit(): void {
    var url = this.router.url;
    console.log("appurl"+url)
    if(this.en){
     //window.location.reload();
    }
    if(url == this.ar){
      this.router.navigate([`/ar/Orderdetails`]);
    }
   }

}
