<section class="main_section">
  <div class="container">
      <div class="title">
         <h1>Track my order</h1>
      </div>
     <div class="row">
          <div class="col-lg-12">
               <div class="tracking_numbers">
                 <h2>Tracking number : <span>WEB00465250</span> </h2>
                 <span class="divide"> | </span>
                 <span>Estimated 21, March  </span>
                </div>
               <div class="order_circle">
                 <div class="order_box active">
                        <div class="order_icon">
                            <img src="../assets/img/order_confirmed.png" class="img-fluid">
                        </div>
                        <h3>Order confirmed</h3>
                    </div>
                  
                   <div class="order_box active">
                        <div class="order_icon">
                             <img src="../assets/img/preparing_order.png" class="img-fluid">
                        </div>
                            <h3>Preparing your order</h3>
                    </div>
     
                    <div class="order_box">
                        <div class="order_icon">
                             <img src="../assets/img/on_its_way.png" class="img-fluid">
                        </div>
                        <h3>On its way</h3>
                    </div>

                    <div class="order_box">
                        <div class="order_icon">
                            <img src="../assets/img/delivered.png" class="img-fluid">
                        </div>
                        <h3>Delivered</h3>
                    </div>
               </div>

              <div class="delivery_status">
                  <h5>Delivery status: <span> Your order is being prepared. </span>  </h5>
              </div>
              <div class="delivery_status">
                 <h5>Last updated on: <span> Wednesday, 28 October 2020 at 11.45 AM </span>  </h5>
              </div>
              <div class="back_to_option">
                 <a href="#">back to home</a>
               </div>
         </div>
      </div>
    </div>
</section>
