import { Routes } from '@angular/router';
import { OrderdetailsComponent } from './orderdetails/orderdetails.component';
import { ArorderdetailsComponent } from './arorderdetails/arorderdetails.component';
import { TrackingstatusComponent } from './trackingstatus/trackingstatus.component';



export const mypage: Routes = [
  { path: "en/Orderdetails", component: OrderdetailsComponent },
  { path: "ar/Orderdetails", component: ArorderdetailsComponent },
  { path: "en/trackstatus", component: TrackingstatusComponent },
  { path: "", redirectTo: "/en/Orderdetails", pathMatch: "full" },
  // { path: "", redirectTo: "/ar/orderdetails", pathMatch: "full" },
];