<div id="trackcontainer">

</div>
<!------------------------Arabic tracking Staus Start--------------------------------------------------->
<section class="main_section"  *ngIf="msgstatus">
  <div class="container">
  <div class="title">
    <h1 class="text-right">تتبع طلبي</h1>
  </div>
  <div class="row" *ngFor="let Orderstatus of alldata">
      <div class="col-lg-12">
        <div class="tracking_numbers">
          <span>{{Orderstatus.estimated_delivery}}</span>
          <span class="divide"> | </span>
          <h2>{{Orderstatus.weborder}}<span>:التتبع رقم</span> </h2>
       </div>
          <div class="order_circle">

            <div class="order_box">
              <div *ngIf="delivered">
                <div class="order_icon_black ">
                  <img src="../assets/ARimg/delivered.png" class="img-fluid">
                </div>
                <h3 class="black">تأكيد الطلب</h3>
              </div>
              <div *ngIf="!delivered">
                <div class="order_icons_grey">
                  <img src="../assets/ARimg/delivered.png" class="img-fluid">
                </div>
                <h3 class="grey">تأكيد الطلب</h3>
              </div>
            </div>

            <div class="order_box">
              <div *ngIf="onItsWay">
                <div class="order_icon_black ">
                  <img src="../assets/ARimg/on_its_way.png" class="img-fluid">
                </div>
                <h3 class="black">معالجة الطلب</h3>
              </div>
              <div *ngIf="!onItsWay">
                <div class="order_icons_grey">
                  <img src="../assets/ARimg/on_its_way.png" class="img-fluid">
                </div>
                <h3 class="grey">معالجة الطلب</h3>
              </div>
            </div>

            <div class="order_box">
              <div *ngIf="prepareYourOrder">
                <div class="order_icon_black ">
                  <img src="../assets/ARimg/preparing_order.png" class="img-fluid">
                </div>
                <h3 class="black"> قيد التوصيل</h3>
              </div>
              <div *ngIf="!prepareYourOrder">
                <div class="order_icons_grey">
                  <img src="../assets/ARimg/preparing_order.png" class="img-fluid">
                </div>
                <h3 class="grey"> قيد التوصيل</h3>
              </div>
            </div>
            <div class="order_box">
              <div *ngIf="orderconfirmed">
                <div class="order_icon_black ">
                  <img src="../assets/ARimg/order_confirmed.png" class="img-fluid">
                </div>
                <h3 class="black"> تم التوصيل</h3>
              </div>
              <div *ngIf="!orderconfirmed">
                <div class="order_icons_grey">
                  <img src="../assets/ARimg/order_confirmed.png" class="img-fluid">
                </div>
                <h3 class="grey"> تم التوصيل</h3>
              </div>
            </div>
              
              
          </div>

          <div class="delivery_status text-right">
            <h5>{{Orderstatus.description}}<span> :حالة الطلب</span> </h5>   
          </div>
          <div class="delivery_status text-right">
              <h5>{{Orderstatus.last_updated_on}}<span>:الاخير محدث على </span></h5>
          </div>
          <div class="timeline">
            <p class="text-right"> :الجدول الزمني</p>
            <ul class="timeline_text" *ngFor="let timeline of status_timeline">
              <li class="timeline_data text-right">{{timeline.description}}&nbsp;({{timeline.last_updated_on}})</li>
            </ul>
          </div>
          <div class="back_to_option text-right">
          <a  (click)="Back()" style="cursor: pointer;">العودة الى الصفحة الرئيسية</a>
          </div>
       </div>
    </div>
       </div>
    </section>

<!------------------------Arabic tracking Staus End------------------------------------------------------------>




<!------------------------------------------Track Order Page Start--------------------------------------------->
<section class="main_section" *ngIf="!msgstatus">
  <div class="container">
     <div class="title">
        <h1 class="text-right">تتبع طلبي</h1>
     </div>
     <div class="row justify-content-center">
        <div class="col-lg-7 col-md-12 col-sm-12">
           
     <div class="tracking_details">
      <h4  class="text-right">:الطلب تتبع رقم ادخل </h4>
        <div class="tracking_input">
           <input type="text" class="form-control"  maxlength="20" placeholder="WEBO0465250 مثال" #weborder>
           <p class="alert_order text-right" *ngIf="dstatus" style=" margin-right: 22px;">{{msg}} <span>&times;</span></p>
        </div>
        <button class="btn btn_black" [disabled]="isLoading == true" (click)="getproduct(weborder.value)">
          <i class ="fa fa-spinner fa-spin fa-1x" *ngIf="isLoading == true"></i> تتبع</button>
     </div>
     <!-- <div class="back_to_option">
        <a href="#">العودة الى الصفحة الرئيسية</a>
     </div> -->
  </div>
</div>
  </div>
</section>
<!------------------------------------------Track Order Page End--------------------------------------------->