import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment.prod';
import { HFserviceService } from '../h-fservice.service';
@Component({
  selector: 'app-arorderdetails',
  templateUrl: './arorderdetails.component.html',
  styleUrls: ['./arorderdetails.component.css']
})
export class ArorderdetailsComponent implements OnInit {

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, location: Location, private hf: HFserviceService) { }
  msgstatus: boolean = false;
  dstatus: boolean;
  msg: string;
  allproduct;
  alldata;
  lang = "ar";
  activeclass = "";
  orderconfirmed = false;
  prepareYourOrder = false;
  onItsWay = false;
  delivered = false;
  none = false;
  status
  isLoading = false;
  status_timeline
  ngOnInit(): void {
    // this.loadAPI = new Promise((resolve) => {
    //   console.log('resolving promise...');
    //   this.loadScript();
    // });
    this.hf.ARloadScript();
    this.hf.en = false;
  }





  /**
   * getproduct() method is used for get tracking data from back-end. 
   * get is used to collect response from url
   * httpOptions used passing header inside get method
   * allproduct variable to store response 
   * alldata variable is used for iterate in html
   * msgstatus variable is boolean type 
   * if this.msgstatus = false then it won't display data in browser
   * Onclick of track button getproduct() method is calling 
  * */




  getproduct(weborder) {
    this.isLoading = true;
    if (weborder != "") {
      weborder = weborder.toUpperCase();
      //setTimeout(() => {
      //  this.isLoading = false;
      //}, 8000)
      //var url ="https://yod-api.youwinn.group/apiman-gateway/AlFuttaimIkea/TrackTrace/1.0/order/"+ weborder +"?lang="+this.lang;
     // var url = "http://63.35.225.103:9092/yod/trackOrderStatusService/" + weborder + "/" + this.lang;
      var url = environment.trackorder_url + weborder + "/" + this.lang;
      console.log("geturl==" + url);
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Basic ' + btoa(environment.UserName + ":" + environment.Password),
        })
      };
      this.http.get(url, httpOptions).subscribe(
        response => {
		  this.isLoading = false;
          this.allproduct = response as string[];
          this.alldata = this.allproduct.data;
          for (let i = 0; i < this.alldata.length; i++) {
            this.status_timeline = this.alldata[i].prev_status_timeline;
            this.activeclass = JSON.stringify(this.alldata[i].frontendcssactiveclass);
            this.status = JSON.stringify(this.alldata[i].status);
            console.log("front===" + this.activeclass);
            if (this.activeclass.includes("NONE")) {
              this.orderconfirmed = false;
              this.prepareYourOrder = false;
              this.onItsWay = false;
              this.delivered = false;
            }
            else if (this.activeclass.includes("CONFIRMED")) {
              this.orderconfirmed = true;
              this.prepareYourOrder = false;
              this.onItsWay = false;
              this.delivered = false;
            }
            else if (this.activeclass.includes("PREPARING")) {
              this.orderconfirmed = true;
              this.prepareYourOrder = true;
              this.onItsWay = false;
              this.delivered = false;
            }
            else if (this.activeclass.includes("SHIPPING")) {
              this.orderconfirmed = true;
              this.prepareYourOrder = true;
              this.onItsWay = true;
              this.delivered = false;
            }
            else if (this.activeclass.includes("DELIVERED")) {
              this.orderconfirmed = true;
              this.prepareYourOrder = true;
              this.onItsWay = true;
              this.delivered = true;
            }
          }
          if (!this.status.includes("Unknown")) {
            this.msgstatus = true;
          }
          else {
            this.msgstatus = false;
            this.msg = "لم نتمكن من العثور على طلب بهذا الرقم. يرجى إعادة إدخال رقم الطلب الخاص بك أو الاتصال بنا على: 8004532";
            this.dstatus = true;
            this.isLoading = false;
          }
        },
        (error) => {
          if (error.status == 404) {
            this.msgstatus = false;
            this.msg = "لم نتمكن من العثور على طلب بهذا الرقم. يرجى إعادة إدخال رقم الطلب الخاص بك أو الاتصال بنا على: 8004532";
            this.dstatus = true;
            this.isLoading = false;

          }
        }
      );
    }
    else {
      this.msgstatus = false;
      this.msg = "أدخل رقم المسار الخاص بك";
      this.dstatus = true;
      this.isLoading = false;
    }

  }






  /**  
    * Back method is used for go back from tacking status to track order page
    * msgstatus is boolean type 
    * if this.msgstatus = true then it won't go back to track order page
    * In tracking status page on click of back button this method will work
   */



  Back() {
    this.msgstatus = false;
    this.dstatus = false;
	this.isLoading = false;
  }



  /**  
   * loadScript() method is used for load external script
   * The main purpose of this method is load IKEA Header and Footer JS
   * This JS type  node.type = 'text/javascript';
   * In app loading time this method work
  */



  // arurl = 'https://www.ikea.com/ae/en/local-apps/campaign/mshop.js';
  // loadAPI: Promise<any>;
  // public loadScript() {
  //  console.log('preparing to load...')
  //  let node = document.createElement('script');
  //  node.src = this.arurl;
  //  node.type = 'text/javascript';
  //  node.async = true;
  //  node.charset = 'utf-8';
  //  document.getElementsByTagName('head')[0].appendChild(node);
  // }



}
