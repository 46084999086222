import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class HFserviceService {
  constructor(private router: Router) { }
  // enurl = 'https://www.ikea.com/ae/en/local-apps/campaign/mshop.js';
  // arurl = 'https://www.ikea.com/ae/ar/local-apps/campaign/mshop.js';
  // enurl = 'https://www.ikea.com/qa/en/local-apps/campaign/mshop.js';
  // arurl = 'https://www.ikea.com/qa/ar/local-apps/campaign/mshop.js';
  en: boolean
  new
  public loadScript(val) {
    //let node = document.createElement('script');
    //node.src = this.enurl;
    //node.type = 'text/javascript';
    //node.async = true;
    // node.charset = 'utf-8';
    //document.getElementsByTagName('head')[0].appendChild(node);
    var url = this.router.url;
    this.new = "/en/Orderdetails";
    //  this.router.navigate(['/en/Orderdetails']) .then(() => {
    //   window.location.reload();
    // });
    // setTimeout(()=>{
    //   this.router.navigate(['/en/Orderdetails']) .then(() => {
    //        window.location.reload();
    //     });
    // },1000)

    console.log("url----: " + url);
    if (this.new == this.router.url) {

      var sc = document.createElement("script");
      sc.setAttribute("src", environment.enHeaderAndFooterURL);
      sc.setAttribute("type", "text/javascript");
      document.head.appendChild(sc);
      //  this.router.routeReuseStrategy.shouldReuseRoute = function () {
      //   return false;
      // };
    }
  }
  public ARloadScript() {

    // let node = document.createElement('script');
    // node.src = this.enurl;
    // node.type = 'text/javascript';
    // node.async = true;
    // node.charset = 'utf-8';
    // document.getElementsByTagName('head')[0].appendChild(node);
    var url = this.router.url;
    this.new = "/ar/Orderdetails";
    console.log("url----: " + url);
    if (url === this.new) {
      var sc = document.createElement("script");
      sc.setAttribute("src", environment.arHeaderAndFooterURL);
      sc.setAttribute("type", "text/javascript");
      document.head.appendChild(sc);
    }
  }
}
