<div id="trackcontainer">

</div>

<!------------------------------------------Display Tracking data--------------------------------------->
<!--<div>
<div class="card m-2" *ngIf="msgstatus">
  <div class="card-body"  >
    <div class="container-fluid" *ngFor="let Orderstatus of alldata">
      <div class="row">
          <div class="col-6">
              <h6>Status</h6>
          </div>
          <div class="col-6">
            <h5 class="viewdetails">{{Orderstatus.status}}</h5>
          </div>
      </div>
      <div class="row">
          <div class="col-6">
              <h6>Description</h6>
          </div>
          <div class="col-6">
            <h5 class="viewdetails" >{{Orderstatus.description}}</h5>
          </div>
      </div>
      <div class="row">
          <div class="col-6">
              <h6>Last_updated_on</h6>
          </div>
          <div class="col-6">
            <h5 class="viewdetails">{{Orderstatus.last_updated_on }}</h5>
          </div>
      </div>
      <div class="row">
        <div class="col-6">
            <h6>Weborder</h6>
        </div>
        <div class="col-6">
          <h5 class="viewdetails">{{Orderstatus.weborder}}</h5>
        </div>
    </div>
    <div class="row">
      <div class="col-6">
          <h6>Customer Name</h6>
      </div>
      <div class="col-6">
        <h5 class="viewdetails">{{Orderstatus.customer_name}}</h5>
      </div>
  </div>
  <div class="row">
    <div class="col-6">
        <h6>Customer Email</h6>
    </div>
    <div class="col-6">
      <h5 class="viewdetails">{{Orderstatus.customer_email}}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
        <h6>Customer Mobile</h6>
    </div>
    <div class="col-6">
      <h5 class="viewdetails">{{Orderstatus.customer_mobile}}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
        <h6>Order_created_on</h6>
    </div>
    <div class="col-6">
      <h5 class="viewdetails">{{Orderstatus.order_created_on}}</h5>
    </div>
</div>
<div class="row">
  <div class="col-6">
      <h6>Estimated_delivery</h6>
  </div>
  <div class="col-6">
    <h5 class="viewdetails">{{Orderstatus.estimated_delivery}}</h5>
  </div>
</div>
   </div>
  </div>
  <button class="btn btn-primary text-center" (click)="Back()" style="width: 20%;">Back</button>
</div>

</div>-->
<section class="main_section" *ngIf="msgstatus">
  <div class="container">
    <div class="title">
      <h1>Track my order</h1>
    </div>
    <div class="row" *ngFor="let Orderstatus of alldata">
      <div class="col-lg-12">
        <div class="tracking_numbers">
          <h2>Tracking number : <span>{{Orderstatus.weborder}}</span> </h2>
          <span class="divide"> | </span>
          <span>{{Orderstatus.estimated_delivery}}</span>
        </div>


        <div class="order_circle">

          <div class="order_box">
            <div *ngIf="orderconfirmed">
              <div class="order_icon_black ">
                <img src="../assets/img/order_confirmed.png" class="img-fluid">
              </div>
              <h3 class="black">Order confirmed</h3>
            </div>
            <div *ngIf="!orderconfirmed">
              <div class="order_icons_grey">
                <img src="../assets/img/order_confirmed.png" class="img-fluid">
              </div>
              <h3 class="grey" >Order confirmed</h3>
            </div>
          </div>

          
          <div class="order_box">
            <div *ngIf="prepareYourOrder">
              <div class="order_icon_black ">
                <img src="../assets/img/preparing_order.png" class="img-fluid">
              </div>
              <h3 class="black">Preparing your order</h3>
            </div>
            <div *ngIf="!prepareYourOrder">
              <div class="order_icons_grey">
                <img src="../assets/img/preparing_order.png" class="img-fluid">
              </div>
                <h3 class="grey">Preparing your order</h3>
            </div>
          </div>


          <div class="order_box">
            <div *ngIf="onItsWay">
              <div class="order_icon_black ">
                <img src="../assets/img/on_its_way.png" class="img-fluid">
              </div>
              <h3 class="black">On its way</h3>
            </div>
            <div *ngIf="!onItsWay">
              <div class="order_icons_grey">
                <img src="../assets/img/on_its_way.png" class="img-fluid">
              </div>
              <h3 class="grey">On its way</h3>
            </div>
          </div>


          <div class="order_box">
            <div *ngIf="delivered">
              <div class="order_icon_black ">
                <img src="../assets/img/delivered.png" class="img-fluid">
              </div>
              <h3 class="black">Delivered</h3>
            </div>
            <div *ngIf="!delivered">
              <div class="order_icons_grey">
                <img src="../assets/img/delivered.png" class="img-fluid">
              </div>
              <h3 class="grey">Delivered</h3>
            </div>
          </div>
        </div>

        <div class="delivery_status">
          <h5>Delivery status: <span> {{Orderstatus.description}}</span></h5>
        </div>
        <div class="delivery_status">
          <h5>Last updated on: <span>{{Orderstatus.last_updated_on}}</span></h5>
        </div>
        <div class="timeline">
          <p>Timeline:</p>
          <ul *ngFor="let timeline of status_timeline">
            <li class="timeline_data">{{timeline.description}}&nbsp;({{timeline.last_updated_on}})</li>
          </ul>
        </div>
        <div class="back_to_option">
          <a href="#" (click)="Back()">back to home</a>
        </div>
      </div>
    </div>
  </div>
</section>

<!------------------------------------------Display Tracking data End--------------------------------------->


<!------------------------------------------Track Order Page Start--------------------------------------------->

<section class="main_section" *ngIf="!msgstatus">
  <div class="container">
     <div class="title">
        <h1>Track my order</h1>
     </div>
     <div class="row justify-content-center">
        <div class="col-lg-7 col-md-12 col-sm-12">
     <div class="tracking_details">
        <h4>Enter your order tracking number:</h4>
        <div class="tracking_input">
           <input type="text" class="form-control" maxlength="20" placeholder="For example WEBO0465250"  (keyup.enter)="getproduct(weborder.value)" #weborder><!--#weborder-->
           <p class="alert_order"  *ngIf="dstatus">
             <svg viewBox="0 0 24 24" width="16px" height="16px" class="">
               <g fill="none" fill-rule="evenodd"><path fill="#E00751" d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10z"></path><path fill="#FFF" fill-rule="nonzero" d="M13.414 12l4.233 4.234-1.414 1.414L12 13.415l-4.243 4.243-1.414-1.415 4.242-4.242L6.35 7.766l1.415-1.414L12 10.587l4.242-4.243 1.415 1.414-4.243 4.243z"></path></g>
              </svg>{{msg}}</p>
        </div>
        <button class="btn btn_black" [disabled]="isLoading == true" (click)="getproduct(weborder.value)">
          <i class ="fa fa-spinner fa-spin fa-1x" *ngIf="isLoading == true"></i> 
          Track
        </button><!--(click)="getproduct(weborder.value)"-->
     </div>
     <!-- <div class="back_to_option">
        <a href="#">back to home</a>
     </div> -->
  </div>
</div>
  </div>
</section>
<!-- <div class="loading-spinner" *ngIf="isLoading">
  <mat-spinner style="top: 50%; left: 40%;"></mat-spinner>
</div> -->
<!------------------------------------------Track Order Page End--------------------------------------------->
